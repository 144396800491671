import React, { useState, useEffect } from 'react'

import style from '../style/select.module.scss'

const input = React.createRef();

/**
 * Custom select input
 * goes with custom-select.scss
 * @param {Object[]} 	options.options 			Label / Value list
 * @param {string} 		options.className 		Custom css class
 * @param {string} 		options.name 					Input name
 * @param {string} 		options.id 						Input id
 * @param {string} 		options.defaultValue 	Input initial value
 * @param {function} 	options.onChange 			onchange event handler
 */
export default function SelectComponent({
	options, 
	className, 
	name, 
	id, 
	defaultValue,
	onChange,
	label,
	required
}) {

	const [height, setHeight] = useState(0);
	const [selectedValue, setValue] = useState(options.find((option) => {
		return option.label === defaultValue
	}));

	/**
	 * Simulate onchange event
	 * @param  {[type]} ( [description]
	 * @return {[type]}   [description]
	 */
	useEffect(() => {
		onChange({target: { 
			value: selectedValue,
			form: input.current.form,
			name
		}});
	}, [selectedValue]);

	/**
	 * Update selected value
	 * @param  {[type]} value [description]
	 * @return {[type]}       [description]
	 */
	const updateValue = function(option) {
		return (evt) => {
			evt.preventDefault();
			setValue(option);
		}
	}

	/**
	 * Toggle options
	 * @param  {[type]} evt [description]
	 * @return {[type]}     [description]
	 */
	function updateHeight(evt) {
		const { currentTarget } = evt;
		const { length } = options;
		
		if ( height === 0)
			setHeight( currentTarget.offsetHeight * length );
		else
			setHeight(0);
	}

	let aClass = `${className} ${style.customSelect}`;
	let placeholder = label;

	if ( required ) placeholder += '*';
	if ( height > 0 ) aClass += ` ${style['customSelectOpen']}`;

	return (
		<div className = { aClass } onClick = { updateHeight }>
			<div 
				className = { style.customSelect__input }>
				<input 
					ref = { input }
					id = { id }
					name = { name }
					defaultValue = { selectedValue }
					type = 'hidden' />

					<span>{ selectedValue ? selectedValue : placeholder }</span>

					<div 
						style = {{ maxHeight: height }}
						className = { style.customSelect__options }>
						{options.map((option) => {
							const onClick = updateValue(option.label);
							return (
								<span 
									className = { 
										option.label == selectedValue ?  
										style.customSelect__selected : ''
									}
									key = { option.label }
									onClick = { onClick }>{ option.label }</span>
							)
						})}
					</div>
			</div>
		</div>
	)
}

SelectComponent.defaultProps = {
	defaultValue: '',
	className: '',
	name: '',
	id: '',
	onChange: console.log,
	options: []
};